import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://c2e552f56cd9a0c4c68bfe50cc0c424b@o4506893552975872.ingest.us.sentry.io/4508043663507456",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});